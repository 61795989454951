@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.listButton {
  text-align: center !important;
  height: 80px;
}

.listButtonActive {
  text-align: center !important;
  height: 80px;
  color: rgba(64, 199, 0, 1) !important;
}

.doughnut {
  width: 150px;
  margin-right: 20px;
}

.row {
  display: flex;
}

.pickup {
  margin-bottom: 20px;
  color: rgba(0, 31, 71, 1);
}

.text_primary {
  color: rgba(0, 31, 71, 1) !important;
}

.text_secondary {
  color: rgba(10, 160, 244, 1) !important;
}

.text_grey {
  text-transform: uppercase;
  color: rgb(177, 179, 180) !important;
}

.orderUserPhoto {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  margin: 0px 10px 10px 10px;
}

.item_picture {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  margin: 0px 10px 10px 10px;
}

.historyItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 22%;
}

.orderDate {
  height: 50px;
}

.textBreak {
  word-wrap: break-word;
  word-break: break-word;
  white-space: initial;
}

.uprCircle {
  width: 30px;
  height: 30px;
  margin-bottom: 15px;
  border-radius: 30px;
  background-color: rgba(197, 208, 221, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.borderGap {
  width: 20%;
  height: 0.1px;
  border: 2px dashed rgba(197, 208, 221, 1);
  margin-left: -10%;
  margin-right: -10%;
  margin-top: 78px;
}

.historycontent {
  text-align: center;
}

.innerCircle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: rgba(197, 208, 221, 1);
}

.innerCircleActive {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #282c34;
}

.c-pointer {
  cursor: pointer;
}

.chartPaper {
  padding: 15px;
  min-height: 180px;
}

.paper {
  padding: 15px;
  width: 100%;
  border-radius: 10px !important;
}

.backArrow {
  width: 30px;
  height: 30px;
  background-color: #282c34;
  border-radius: 5px;
  margin-right: 15px;
  margin-top: -5px;
  cursor: pointer;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowCenter {
  display: flex;
  align-items: center !important;
}

.greyBox {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  margin-bottom: 10px;
  margin-right: 15px;
  background-color: rgba(241, 241, 242, 1);
}

.greenBox {
  width: 20px;
  margin-right: 15px;
  height: 20px;
  border-radius: 3px;
  background-color: rgba(64, 199, 0, 1);
}

.orangeBox {
  width: 20px;
  margin-right: 15px;
  height: 20px;
  border-radius: 3px;
  background-color: rgba(255, 129, 24, 1)
}

.font-14 {
  font-size: 14px;
}

.font-24 {
  font-size: 24px;
}

.header {
  margin-top: 20px !important;
}

.search {
  width: 100%;
}

.search input {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: none;
}

.form-element {
  position: relative;
}

.search .fa-search {
  position: absolute;
  top: 0;
  right: 10px;
  bottom: 0;
}

input:focus {
  outline: none;
}

.ProfileImage {
  width: 100px;
  height: 100px;
  border-radius: 100px;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 31, 71, 1);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.feedbackUserImage {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-right: 15px;
}

.zipModal {
  padding: 20px !important;
  width: 400px;
}

.zipcode {
  width: 100%;
  height: 50px;
  padding-left: 15px;
  border-radius: 10px;
  border: 1px solid #dfdada;

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}